<template>
  <div id="editPolitic" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5">EDITAR COMUNICADO</v-row>
      <v-container fluid class="addForms" style="margin-top: 2%">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="4" class="ml-5 formularios">
            <v-row>
              <!--Razon Social-->
              <label for="reason">Razón social</label>
              <select
                name="reason"
                id="reason"
                v-model="politicSelect.empresaId"
                disabled
              >
                <option selected disabled value="0">Selecciona Razon</option>
                <option
                  v-for="reason in enterprises"
                  :key="reason.id"
                  v-bind:value="reason.id"
                >
                  {{ reason.razonSocial }}
                </option>
              </select>
            </v-row>
            <v-row>
              <!--Comunicado-->
              <label for="event">Comunicado</label>
              <input
                type="text"
                name="event"
                id="event"
                placeholder="Titulo del Comunicado"
                class="inputs"
                autocomplete="false"
                v-model="politicSelect.tituloComunicado"
                maxlength="20"
                @keypress="alfaNumerico($event)"
              />
              <br />
              <!--No delete target label-->
              <label></label>
              <textarea
                id="eventText"
                placeholder="Editor de texto..."
                v-model="politicSelect.descripcionComunicado"
                @keypress="alfaNumerico($event)"
              ></textarea>

              <!--LOGOTIPO-->
              <label for="logo">Imagen</label>
              <input
                type="file"
                name="logo"
                id="log"
                class="inputs"
                @change="onFileChange($event)"
              />
            </v-row>
            <v-row>
              <!--Envio-->
              <label for="sender">Envio</label>
              <input
                type="text"
                name="sender"
                id="sender"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="personaNotificadora"
                maxlength="20"
                @keypress="alfaNumerico($event)"
                disabled
              />
            </v-row>
            <v-row>
              <!--Envio-->
              <label for="startDate">Fecha de Inicio</label>
              <input
                type="date"
                name="startDate"
                id="startDate"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="politicSelect.fechaInicio"
                maxlength="20"
                :min="fecha_minimo"
                @keypress="alfaNumerico($event)"
                @blur="validacionFechas()"
              />
            </v-row>
            <v-row>
              <!--Envio-->
              <label for="finishDate">Fecha de Fin</label>
              <input
                type="date"
                name="finishDate"
                id="finishDate"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="politicSelect.fechaFin"
                maxlength="20"
                :min="fecha_minimo"
                @keypress="alfaNumerico($event)"
                @blur="validacionFechas()"
              />
            </v-row>
            <v-row
              v-if="
                politicSelect.estatusPoliticaId == 1 ||
                politicSelect.estatusPoliticaId == null
              "
            >
              <!-- <div class="form-group"> -->
              <label for="activa">Listo para publicar</label>
              <v-checkbox
                name="activa"
                id="activa"
                autocomplete="false"
                v-model="activa"
                size="20"
              ></v-checkbox>
              <!--  </div> -->
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="7" align-self="center">
            <v-row justify="center" id="previewRow">
              <img
                id="preview"
                v-if="politicSelect.imagenPolitica != ''"
                :src="politicSelect.imagenPolitica"
                alt="Logotipo"
              />
            </v-row>
            <v-row justify="center">
              <button class="botonAmarillo" @click="validacion()">
                Guardar
              </button>
              <button class="botonAmarilloDerecho" @click="cancel()">
                Regresar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Edición de Política</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="300">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de modificar el comunicado?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Modificación de Política</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      personaNotificadora:"",
      dialog: false,
      errors: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
      enterprises: [],
      areas: [],
      jobs: [],
      employees: [],
      person: localStorage.nombreEmpleadoGlobal,
      url: null,
      formData: new FormData(),
      politicSelect: [],
      activa: false,
      fecha_minimo: "",
    };
  },
  methods: {
    validacionFechas() {
      if (
        this.politicSelect.fechaInicio != "" &&
        this.politicSelect.fechaFin == ""
      )
        //console.log("solo se lleno fecha inicial");
      if (
        this.politicSelect.fechaFin != "" &&
        this.politicSelect.fechaInicio == ""
      )
        //console.log("solo se lleno fecha final");
      if (
        this.politicSelect.fechaInicio != "" &&
        this.politicSelect.fechaFin != ""
      ) {
        //console.log("ambas fechas tienen valors");
        if (this.politicSelect.fechaInicio > this.politicSelect.fechaFin) {
          this.titulo = "Fechas Solicitadas";
          this.respuesta =
            "La fecha de inicio debe ser diferente, favor de corregirlo";
          this.error = true;
        }
      }
    },
    cancel() {
      localStorage.politicId = "";
      this.$router.push("/politics");
    },
    guardar() {
      this.advice = false;
      if (this.activa) {
        this.politicSelect.estatusPoliticaId = 2;
      }
      this.formData.append("EmpresaId", this.politicSelect.empresaId);
      //this.formData.append("AreaId", this.politicSelect.areaId);
      this.formData.append(
        "TituloComunicado",
        this.politicSelect.tituloComunicado
      );
      this.formData.append(
        "DescripcionComunicado",
        this.politicSelect.descripcionComunicado
      );
      this.formData.append(
        "EmpleadoEnviaId",
        this.politicSelect.empleadoEnviaId
      );
      this.formData.append("FechaInicio", this.politicSelect.fechaInicio);
      this.formData.append("FechaFin", this.politicSelect.fechaFin);
      this.formData.append(
        "EstatusPoliticaId",
        this.politicSelect.estatusPoliticaId
      );
      this.show = true;
      axios
        .put(Server + "/politicas/" + this.politicSelect.id, this.formData, {
          headers: {
            Authorization: localStorage.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.enterprise = "";
          this.area = "";
          this.job = "";
          this.employee = "";
          this.sender = "";
          this.event = "";
          this.eventText = "";
          this.startDate = "";
          this.finishDate = "";
          this.respuesta = "El comunicado fue guardado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.reason = "";
            this.area = "";
            this.job = "";
            this.employee = "";
            this.sender = "";
            this.event = "";
            this.eventText = "";
            this.startDate = "";
            this.finishDate = "";
            this.respuesta = "El comunicado no fue actualizado";
            this.confirmation = true;
          }
        });
    },
    onFileChange(evt) {
      const file = evt.target.files[0];
      this.url = URL.createObjectURL(file);
      var fileBase = evt.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        //console.log("RESULT", reader.result);
        sessionStorage.setItem("base64img", reader.result);
      };
      reader.readAsDataURL(fileBase);
      this.selectedFile = evt.target.files[0];
      this.formData.append(
        "imagenPolitica",
        this.selectedFile,
        this.selectedFile.name
      );
    },

    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function () {
      this.errors = [];
      if (this.politicSelect.empresaId == 0) {
        this.errors.push("Se debe seleccionar la razón social.");
      }
      if (
        this.politicSelect.empleadoEnviaId == "" ||
        this.politicSelect.empleadoEnviaId == " "
      ) {
        this.errors.push("Se debe especificar la persona que envía.");
      }
      if (
        this.politicSelect.tituloComunicado == "" ||
        this.politicSelect.tituloComunicado == " "
      ) {
        this.errors.push("Se debe especificar título de comunicado.");
      }
      if (
        this.politicSelect.descripcionComunicado == "" ||
        this.politicSelect.descripcionComunicado == " "
      ) {
        this.errors.push("Se debe especificar texto del comunicado.");
      }
      if (
        this.politicSelect.fechaInicio == "" ||
        this.politicSelect.fechaInicio == " "
      ) {
        this.errors.push("Se debe especificar fecha de inicio del comunicado.");
      }
      if (
        this.politicSelect.fechaFin == "" ||
        this.politicSelect.fechaFin == " "
      ) {
        this.errors.push("Se debe especificar fecha de fin del comunicado.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    aux() {
      this.advice = false;
      this.confirmation = false;
      localStorage.politicId = "";
      this.$router.push("/politics");
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    format_date(fecha1, fecha2) {
      //console.log("fecha pasada" + fecha1);
      var aux = fecha1.substr(0, 10);
      //console.log("fecha obtenida" + aux);
      var aux2 = fecha2.substr(0, 10);
      this.politicSelect.fechaInicio = aux;
      this.politicSelect.fechaFin = aux2;
      return true;
    },
    recuperar() {
      //console.log(localStorage.enterpriseId);
      this.show = true;
      axios
        .get(Server + "/politicas/" + localStorage.politicId, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.personaNotificadora = response.data.empleadoEnvia.nombre + " "+response.data.empleadoEnvia.apellidoPaterno+" "+response.data.empleadoEnvia.apellidoMaterno;
          this.politicSelect = response.data;
          this.format_date(response.data.fechaInicio, response.data.fechaFin);
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    fechasMinimo() {
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var dia = fecha.getDate();
      var _mes = fecha.getMonth(); //0 al 11
      _mes = _mes + 1; //de 1 al 12
      if (_mes < 10) {
        //agrega un 0
        var mes = "0" + _mes;
      } else {
        var mes = _mes.toString();
      }
      this.fecha_minimo = anio + "-" + mes + "-" + dia; // Nueva variable
    },
  },
  created() {
    sessionStorage.setItem("base64img", null);
    this.fechasMinimo();
    this.listar();
    this.recuperar();
  },
};
</script>